import React from 'react';
import styled from 'styled-components';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { Page, Section, Block, Header } from 'src/components';

const QUERY = graphql`
  query {
    allGraphCmsAgreement(filter: { displaySite: { eq: true } }) {
      nodes {
        id
        title
        slug
        order
        company
        externalUrl
      }
    }
  }
`;

const SLink = styled(Link)`
  display: block;
  padding: 4px 0;
  font-size: var(--fs-b3);
  &:first-child {
    margin-top: 24px;
  }
`;

const getDocsByCompany = (list, company) =>
  list?.filter((a) => a.company === company)?.sort((a, b) => a.order - b.order);

export default function Legal() {
  const data = useStaticQuery(QUERY);
  const posts = data?.allGraphCmsAgreement?.nodes;
  return (
    <Page title="Legal" path="legal">
      <Header narrow title="Legal" bg="" />
      <Section narrow raw>
        <Block w={600} light small title="Catch" subtitle="Catch Financial, LLC">
          {getDocsByCompany(posts, 'catch')?.map((post) => (
            <SLink
              key={post.externalUrl}
              className="u link"
              to={post.externalUrl ? post.externalUrl : '/legal/' + post.slug}
            >
              {post.title}
            </SLink>
          ))}
        </Block>

        <Block
          w={600}
          light
          small
          title="Catch Insurance"
          subtitle="Catch Insurance, LLC &amp; Catch Insurance Agency, LLC"
        >
          {getDocsByCompany(posts, 'catch_insurance')?.map((post) => (
            <SLink
              key={post.externalUrl}
              className="u link"
              to={post.externalUrl ? post.externalUrl : '/legal/' + post.slug}
            >
              {post.title}
            </SLink>
          ))}
        </Block>

        <Block w={600} light small title="Catch Money" subtitle="Catch Money, LLC">
          {getDocsByCompany(posts, 'catch_money')?.map((post) => (
            <SLink
              key={post.externalUrl}
              className="u link"
              to={post.externalUrl ? post.externalUrl : '/legal/' + post.slug}
            >
              {post.title}
            </SLink>
          ))}
        </Block>
      </Section>
      <Section small></Section>
    </Page>
  );
}
